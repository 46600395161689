<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ $t('page.reports') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
      </div>
      <div class="table-filters">
        <div class="col-type">
          <b-form-radio-group
            id="type-report"
            v-model="reportsParams.type"
            class="mb-1"
            button-variant="outline-primary"
            :options="optionsRadioLocale"
            buttons
            size="sm"
            name="radios-btn-default"
          />
        </div>
        <div class="col-picker">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangePicker"
              :config="{ mode: 'range', dateFormat: 'Y-m-d'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
              @on-close="changeRange"
            />
          </div>
        </div>
        <div class="col-user_id">
          <v-select
            v-model="partner"
            class="select-size-sm"
            :options="agencies"
            :placeholder="$t('page.features.chooseagency')"
          />
        </div>
        <div class="col-export">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            @click="fetchReportsexport"
          >
            {{ $t('page.features.exportstats') }}
          </b-button>
        </div>
      </div>
      <b-row>
        <b-col cols="12">
          <div
            v-if="loading"
            class="d-flex justify-content-center mb-1"
          >
            <b-spinner label="Loading..." />
          </div>
          <div v-else>
            <component
              :is="`${reportsParams.type}Table`"
              :fields="tableHeadersLocale[reportsParams.type]"
              :rows="reports"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import getFormattedDate from '@/utils/date-formatter'
import {
  BCard,
  BRow,
  BCol,
  BFormRadioGroup,
  BSpinner,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import flatPickr from 'vue-flatpickr-component'
import SitesTable from './SitesTable.vue'
import CampaignsTable from './CampaignsTable.vue'
import AffiliatesTable from './AffiliatesTable.vue'
import ClicksTable from './ClicksTable.vue'
import EcpmsTable from './EcpmsTable.vue'
import CriteoTable from './CriteoTable.vue'

const defaultDateFrom = getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
const defaultDateTo = getFormattedDate(new Date())

export default {
  components: {
    BCard,
    BRow,
    BCol,
    Breadcrumbs,
    flatPickr,
    AffiliatesTable,
    SitesTable,
    ClicksTable,
    EcpmsTable,
    CampaignsTable,
    CriteoTable,
    BFormRadioGroup,
    BSpinner,
    vSelect,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      getFormattedDate,
      fields: {
        sites: [
          { key: 'title', label: 'Site`s name' },
          { key: 'owner', label: 'User' },
          { key: 'email', label: 'Email' },
          { key: 'balance', label: 'Current balance' },
          { key: 'amount', label: 'Income', sortable: true },
          { key: 'show', label: 'Impressions', sortable: true },
          { key: 'ecpm', label: 'eCPM', sortable: true },
          { key: 'payment_method', label: 'Payment method', class: 'payment-cell' },
        ],
        campaigns: [
          { key: 'title', label: 'Campaign`s name', class: 'title-cell' },
          { key: 'owner', label: 'User' },
          { key: 'email', label: 'Email' },
          { key: 'balance', label: 'Current balance' },
          { key: 'show', label: 'Impressions' },
          { key: 'clicks', label: 'Clicks' },
          { key: 'amount', label: 'Spent' },
          { key: 'dailyclicks', label: 'Daily clicks limit' },
        ],
        clicks: [
          { key: 'title', label: 'Site`s name' },
          { key: 'amount', label: 'Clicks' },
        ],
        affiliates: [
          { key: 'title', label: 'Site`s name' },
          { key: 'owner', label: 'User' },
          { key: 'email', label: 'Email' },
          { key: 'balance', label: 'Current balance' },
          { key: 'amount', label: 'Income' },
        ],
        ecpms: [
          { key: 'title', label: 'Site`s name' },
          { key: 'owner', label: 'User' },
          { key: 'email', label: 'Email' },
          { key: 'balance', label: 'Current balance' },
          { key: 'amount', label: 'Income' },
        ],
        criteo: [
          { key: 'title', label: 'Site`s name' },
          { key: 'owner', label: 'User' },
          { key: 'email', label: 'Email' },
          { key: 'balance', label: 'Current balance' },
          { key: 'amount', label: 'Income' },
        ],
      },
      reports: [],
      agencies: [],
      breadcrumbItems: [],
      optionsRadio: [
        { text: this.$t('sites.sites'), value: 'sites' },
        { text: this.$t('menu.campaigns'), value: 'campaigns' },
        { text: this.$t('selects.chart.clicks'), value: 'clicks' },
        { text: this.$t('page.features.affiliates'), value: 'affiliates' },
        { text: this.$t('page.features.openrtb'), value: 'ecpms' },
        { text: 'Criteo', value: 'criteo' },
      ],
      rangePicker: [defaultDateFrom, defaultDateTo],
      reportsParams: {
        from: defaultDateFrom,
        to: defaultDateTo,
        type: 'affiliates',
        user_id: null,
      },
      partner: null,
    }
  },
  computed: {
    optionsRadioLocale() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`page.features.${o.value}`),
      }))
    },
    tableHeadersLocale() {
      const fields = {}
      Object.keys(this.fields).forEach(key => {
        fields[key] = this.fields[key].map(h => ({
          ...h,
          label: this.$t(`reportsTableHeaders.${key}.${h.key}`),
        }))
      })
      return fields
    },
    filtredParams() {
      const urlParams = {}
      Object.keys(this.reportsParams).forEach(p => {
        if (this.reportsParams[p] !== '' && this.reportsParams[p] !== null) {
          urlParams[p] = this.reportsParams[p]
        }
      })
      return urlParams
    },
  },
  watch: {
    reportsParams: {
      handler(newVal) {
        this.reportsParams = Object.assign(this.reportsParams, newVal)
        this.$router.replace({
          name: 'dashboard-reports',
          query: this.filtredParams,
        }).catch(() => {})
        this.fetchReports(this.filtredParams)
      },
      deep: true,
    },
    partner(newVal) {
      if (newVal !== null) {
        this.reportsParams.user_id = newVal.value
      } else {
        this.reportsParams.user_id = null
      }
    },
  },
  created() {
    this.breadcrumbItems = [
      {
        text: this.$i18n.t('page.reports'),
        to: '/reports/',
        active: true,
      },
    ]
    this.getReports()
    this.fethAgencies()
  },
  methods: {
    getReports() {
      const paramsFromUrl = this.getParamsFromUrl()
      if (Object.keys(paramsFromUrl).length !== 0) {
        this.reportsParams = Object.assign(this.reportsParams, paramsFromUrl)
        this.rangePicker = [this.reportsParams.from, this.reportsParams.to]
      } else {
        this.$router.replace({
          name: 'dashboard-reports',
          query: this.filtredParams,
        }).catch(() => {})
        this.fetchReports(this.filtredParams)
      }
    },
    async fetchReports(params) {
      const paramsFromUrl = this.getParamsFromUrl()
      params = Object.assign(params, paramsFromUrl)
      if (params.from && params.to && params.type) {
        this.loading = true
        this.reports = null

        await useJwt.reports(params).then(response => {
          this.reports = response.data.reports
        }).finally(() => {
          this.loading = false
        })
      }
    },
    getParamsFromUrl() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      const paramsFromUrl = {}
      Object.keys(urlParams).forEach(p => {
        if (this.reportsParams[p]) {
          paramsFromUrl[p] = urlParams[p]
        }
      })
      return paramsFromUrl
    },
    async changeRange(range) {
      const [from, to] = range
      this.reportsParams = Object.assign(this.reportsParams, { from: getFormattedDate(from), to: getFormattedDate(to) })
    },
    async fethAgencies() {
      const responseData = await useJwt.agencies()
      this.agencies = responseData.data.agencies.map(a => ({ value: a._id, label: a.title })) || []
    },
    async fetchReportsexport() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      if (this.reportsParams.type !== 'affiliates') {
        await useJwt.reportsexport({
          from: dateFrom,
          to: dateTo,
          type: this.reportsParams.type,
          user_id: this.partner?.value,
        }).then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${this.reportsParams.type}_reports.csv`
          link.click()
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-user_id {
    min-width: 250px;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }

  @media (max-width: 570px) {
    .col-user_id {
      margin-right: 10px;
    }

    #type-report {
      flex-wrap: wrap;
    }

    #type-report > label {
      border-right-width: 1px!important;
      border-radius: 0!important;
      margin-left: -1px!important;
      margin-bottom: -1px;
    }
  }

  @media (max-width: 394px) {
    .col-user_id,
    .col-export {
      width: 100%;
      margin: 10px 0;
    }

    .col-export button {
      width: 100%;
    }
  }

  .title-cell {
    max-width: 400px;
  }
</style>
